import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import http from '../../infrastructure/api/http';
import { GeneralReportCustomer } from './CustomerSelector';

type ResponseData = {
  id_centro: number;
  nom_centro: string;
  consumo_mes_actual: number;
};

type GeneralEnergyMonthProps = {
  company: GeneralReportCustomer;
};

const GeneralEnergyMonth: FC<GeneralEnergyMonthProps> = ({ company }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);

  const loadData = async (customerId: number) => {
    try {
      setLoading(true);
      const response = await http.get<ResponseData[]>(
        `/general-report/customers/${customerId}/centers/month-consumption`
      );
      setData([
        ['AR', 'Consumo Mes'],
        ...response.data.map((item) => [
          item.nom_centro,
          item.consumo_mes_actual === 0 ? 0.001 : item.consumo_mes_actual,
        ]),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card>
      <CardHeader
        title={
          <Box component="div" display="flex" flexDirection="row" gap="5px">
            <Typography variant={'body1'} fontWeight={600}>
              Consumo de energía
            </Typography>
            <Typography variant={'body1'}>
              | {moment().format('MMMM')} {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        {loading && (
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && data.length === 0 && (
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="5px"
          >
            <Typography variant="h5" fontWeight={600}>
              No hay datos disponibles
            </Typography>
            <Typography variant="body1">
              No hay datos disponibles para el mes actual
            </Typography>
          </Box>
        )}
        {!loading && data.length > 0 && (
          <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data}
            options={{
              pieHole: 0.5,
              backgroundColor: 'transparent',
              legend: {
                position: 'right',
                textStyle: {
                  color: theme.palette.text.primary,
                },
              },
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default GeneralEnergyMonth;
