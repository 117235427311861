import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { GeneralReportCenter } from '../CustomerSelector';

type EnergyQualityProps = {
  center: GeneralReportCenter;
};

const EnergyQuality: React.FC<EnergyQualityProps> = ({ center }) => {
  const [data, setData] = useState(-1);

  return (
    <Card style={{ height: '250px' }}>
      <CardHeader
        title={
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="5px"
            flexWrap={'wrap'}
          >
            <Typography variant="body1" fontWeight={600}>
              Calidad |
            </Typography>
            <Typography variant="body1">de energía</Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap="5px"
              flexWrap={'wrap'}
            >
              <Typography variant="h5" fontWeight={600}>
                {data === -1 ? '0' : data.toFixed(2)}
              </Typography>
              <Typography variant="body1">V</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" style={{ borderTop: '1px solid #009581' }} />
          </Grid>
          <Grid item xs={12}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              flexWrap={'wrap'}
            >
              <Typography variant="body1" fontSize={11} color="#009581">
                Sobretensión 15.668%
              </Typography>
              <Typography variant="body1" fontSize={11} color="#009581">
                Subtensión 0%
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EnergyQuality;
