import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { GeneralReportCenter } from '../CustomerSelector';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BoltIcon from '@mui/icons-material/Bolt';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../../domain/store/ui/selectors';

type GeneralStatisticsProps = {
  center: GeneralReportCenter;
};

const GeneralStatistics: React.FC<GeneralStatisticsProps> = ({ center }) => {
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const [anualConsumption, setAnualConsumption] = useState('-1');

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: 'background.default',
          p: 1,
        }}
        title={<Typography variant={'body1'}>{center.name}</Typography>}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              borderRight: isSmallScreen ? 'none' : '1px solid #ddd',
            }}
          >
            <Box
              component={'div'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={1}
            >
              <Box component={'div'} display={'flex'} flexDirection={'row'}>
                <BoltIcon />
                <Typography variant={'body1'}>Mensual</Typography>
              </Box>
              <Typography variant={'h6'}>
                {center.statuses.length} Kw
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component={'div'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={1}
            >
              <Box component={'div'} display={'flex'} flexDirection={'row'}>
                <BoltIcon />
                <Typography variant={'body1'}>Consumo Anual</Typography>
              </Box>
              <Box
                component={'div'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                alignItems={'center'}
              >
                <Typography variant={'h6'}>
                  {anualConsumption === '-1' ? '-' : anualConsumption}
                </Typography>
                <Typography variant={'body2'}>MWh</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GeneralStatistics;
