import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import GeneralStatisticsCompany from './GeneralStatisticsCompany';
import GeneralEnergyYear from './GeneralEnergyYear';
import GeneralEnergyMonth from './GeneralEnergyMonth';
import GeneralEnergyYearBars from './GeneralEnegryYearBars';
import GeneralEnergyYearDonut from './GeneralEnergyYearDonut';
import GeneralEnergyProjectedConsumption from './GeneralEnergyProjectedConsumption';
import { GeneralReportCustomer } from './CustomerSelector';
import GeneralEnergyConsumedMonth from './GeneralEnergyConsumedMonth';
import GeneralEnergyConsumedYearBars from './GeneralEnergyConsumedYearBars';
import GeneralEnergyMaximumPowerDemand from './GeneralEnergyMaximumPowerDemand';

type GeneralResumeProps = {
  company: GeneralReportCustomer;
};
const GeneralResume: FC<GeneralResumeProps> = ({ company }) => {
  return <Box component={'div'} sx={{ p: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <GeneralStatisticsCompany company={company} />
      </Grid>
      <Grid item xs={0} md={8}></Grid>
      <Grid item xs={12} md={6}>
        <GeneralEnergyYear company={company} />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralEnergyMonth company={company} />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralEnergyYearBars company={company} />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralEnergyYearDonut company={company} />
      </Grid>
      <Grid item xs={12} md={3}>
        <GeneralEnergyConsumedMonth company={company} />
      </Grid>
      <Grid item xs={12} md={3}>
        <GeneralEnergyProjectedConsumption company={company} />
      </Grid>
      <Grid item xs={12} md={6}>
        <GeneralEnergyConsumedYearBars company={company} />
      </Grid>
      <Grid item xs={12}>
        <GeneralEnergyMaximumPowerDemand company={company} />
      </Grid>
    </Grid>
  </Box>;
};

export default GeneralResume;
