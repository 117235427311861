import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { GeneralReportCustomer } from './CustomerSelector';
import http from '../../infrastructure/api/http';

type ResponseData = {
  id_centro: number;
  nom_centro: string;
  consumo_mes_actual: number;
};

type GeneralEnergyYearDonutProps = {
  company: GeneralReportCustomer;
};

const GeneralEnergyConsumedMonth: FC<GeneralEnergyYearDonutProps> = ({
  company,
}) => {
  const [data, setData] = useState(-1);

  const loadData = async (customerId: number) => {
    try {
      const response = await http.get<ResponseData[]>(
        `/general-report/customers/${customerId}/centers/month-consumption`
      );
      setData(
        response.data.reduce((acc, curr) => acc + curr.consumo_mes_actual, 0)
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card>
      <CardHeader
        title={
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="5px"
            flexWrap={'wrap'}
          >
            <Typography variant="body1" fontWeight={600}>
              Energia consumida |
            </Typography>
            <Typography variant="body1">
              {moment().format('MMMM')} {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap="5px"
              flexWrap={'wrap'}
            >
              <Typography variant="h5" fontWeight={600}>
                {data === -1 ? '-' : data.toFixed(2)}
              </Typography>
              <Typography variant="body1">MWh</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" style={{ borderTop: '1px solid #009581' }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="#009581">
              Energía
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GeneralEnergyConsumedMonth;
