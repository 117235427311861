import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import { Alert, Box, Button, Grid, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import CenterSelector from '../components/generalResumeCenter/CenterSelector';
import { useParams } from 'react-router-dom';
import { GeneralReportCenter } from '../components/CustomerSelector';
import GeneralResumeCenterComponent from '../components/generalResumeCenter/GeneralResumeCenter';

const GeneralResumeCenter = () => {
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [center, setCenter] = useState<GeneralReportCenter | null>(null);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const params = useParams();
  const customerId = params?.customerId;
  const centerId = params?.centerId;

  if (!centerId || !customerId) {
    return <></>;
  }

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <Typography component="h1" variant="h5" flex={1}>
            Resumen general
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setOpenModal(true)}
          >
            {center ? center.name : 'Seleccionar Centro'}
          </Button>
        </Grid>
        {!center && (
          <Grid item xs={12} style={{ paddingTop: isSmallScreen ? 20 : 55 }}>
            <Alert severity="info">
              Bienvenido al dashboard <strong>Resumen General</strong>. No hay
              un centro seleccionado, por favor elija el centro y haga click en
              aceptar.
            </Alert>
          </Grid>
        )}
        {center && (
          <Grid item xs={12} style={{ paddingTop: isSmallScreen ? 20 : 55 }}>
            <GeneralResumeCenterComponent center={center} />
          </Grid>
        )}
      </Grid>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmallScreen ? '80%' : '50%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 2,
          }}
        >
          <CenterSelector
            customerId={parseInt(customerId)}
            centerId={parseInt(centerId)}
            onCancel={() => {
              setOpenModal(false);
            }}
            onConfirm={(center) => {
              setCenter(center);
              setOpenModal(false);
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default GeneralResumeCenter;
