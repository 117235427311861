import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { useState } from 'react';
import { GeneralReportCenter } from '../CustomerSelector';

type PotencyMaxMonthProps = {
  center: GeneralReportCenter;
};

const PotencyMaxMonth: React.FC<
  PotencyMaxMonthProps
> = ({ center }) => {
  const [data, setData] = useState(-1);
  const [percent, setPercent] = useState(0);

  return (
    <Card style={{ height: '250px' }}>
      <CardHeader
        title={
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="5px"
            flexWrap={'wrap'}
          >
            <Typography variant="body1" fontWeight={600}>
              Potencia máxima |
            </Typography>
            <Typography variant="body1">
              {moment().format('MMMM')} {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap="5px"
              flexWrap={'wrap'}
            >
              <Typography variant="h5" fontWeight={600}>
                {data === -1 ? '0' : data.toFixed(2)}
              </Typography>
              <Typography variant="body1">kW</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" style={{ borderTop: '1px solid #009581' }} />
          </Grid>
          <Grid item xs={12}>
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              gap="5px"
              flexWrap={'wrap'}
              alignItems={'center'}
            >
              {percent > 0 ? (
                <>
                  <NorthIcon
                    fontSize="small"
                    style={{ color: '#009581', fontWeight: 600 }}
                  />
                  <Typography variant="body1" color="#009581">
                    {percent.toFixed(2)} % que el mes anterior
                  </Typography>
                </>
              ) : (
                <>
                  <SouthIcon
                    fontSize="small"
                    style={{ color: '#e74c3c', fontWeight: 600 }}
                  />
                  <Typography variant="body1" color="#e74c3c">
                    {Math.abs(percent).toFixed(2)} % que el mes anterior
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PotencyMaxMonth;
