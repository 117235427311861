import React, { useState } from 'react'
import { GeneralReportCenter } from '../CustomerSelector';
import { Box, Card, CardContent, CardHeader, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { Chart } from 'react-google-charts';

type EnergyConsumedByStatusProps = {
  center: GeneralReportCenter;
};

const EnergyConsumedByStatus: React.FC<EnergyConsumedByStatusProps> = ({ center }) => {
  const theme = useTheme();
  const [data, setData] = useState([
    ['', '', { role: 'annotation' }],
    ['Diciembre', 0, '0MWh'],
    ['Noviembre', 0, '0MWh'],
    ['Octubre', 0, '0MWh'],
    ['Septiembre', 0, '0MWh'],
    ['Agosto', 0, '0MWh'],
    ['Julio', 0, '0MWh'],
    ['Junio', 0, '0MWh'],
    ['Mayo', 0, '0MWh'],
    ['Abril', 0, '0MWh'],
    ['Marzo', 0, '0MWh'],
    ['Febrero', 0, '0MWh'],
    ['Enero', 0, '0MWh'],
  ]);

  return (
    <Card>
      <CardHeader
        title={
          <Box component="div" display="flex" flexDirection="row" gap="5px">
            <Typography variant={'body1'} fontWeight={600}>
              Energía
            </Typography>
            <Typography variant={'body1'}>
              | consumida por AR
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data}
          options={{
            backgroundColor: 'transparent',
            chartArea: { height: '80%' },
            hAxis: {
              minValue: 0,
              textStyle: {
                color: theme.palette.text.primary,
              },
              format: '###MWh',
            },
            vAxis: {
              textStyle: {
                color: theme.palette.text.primary,
              },
            },
            legend: 'none',
            annotations: {
              alwaysOutside: false,
              textStyle: {
                color: theme.palette.text.primary,
                fontSize: 10,
              },
            },
          }}
        />
      </CardContent>
    </Card>
  )
}

export default EnergyConsumedByStatus