import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import http from '../../infrastructure/api/http';

type GeneralEnergyYearDonutProps = {
  company: any;
};

const GeneralEnergyYearDonut: FC<GeneralEnergyYearDonutProps> = ({
  company,
}) => {
  const theme = useTheme();
  const [data, setData] = useState([['Task', 'Hours per Day']]);

  const loadData = async (customerId: number) => {
    try {
      const response = await http.get<any[]>(
        `/general-report/customers/${customerId}/centers/consumption-year-by-center`
      );
      setData([
        ['Centro', 'Consumo Año'],
        ...response.data.map((item) => [
          item.nombre_centro,
          parseFloat(item.energia_total_consumida.toFixed(2)),
        ]),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card>
      <CardHeader
        title={
          <Box component="div" display="flex" flexDirection="row" gap="5px">
            <Typography variant={'body1'} fontWeight={600}>
              Consumo de energía
            </Typography>
            <Typography variant={'body1'}>
              | este año {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={data}
          options={{
            pieHole: 0.5,
            backgroundColor: 'transparent',
            legend: {
              position: 'right',
              textStyle: {
                color: theme.palette.text.primary,
              },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default GeneralEnergyYearDonut;
