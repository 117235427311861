import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { GeneralReportCustomer } from './CustomerSelector';
import http from '../../infrastructure/api/http';

type ResponseData = {
  id_center_status: number;
  nombre_center_status: string;
  potencia_maxima_anual: number;
};

type GeneralEnergyYearBarsProps = {
  company: GeneralReportCustomer;
};
const GeneralEnergyMaximumPowerDemand: FC<GeneralEnergyYearBarsProps> = ({
  company,
}) => {
  const theme = useTheme();
  const [data, setData] = useState<any[]>([]);

  const loadData = async (customerId: number) => {
    try {
      const response = await http.get<ResponseData[]>(
        `/general-report/customers/${customerId}/anual-max-potency-demand`
      );
      setData([
        ['', 'MWh', { role: 'style' }, { role: 'annotation' }],
        ...response.data.map((item) => [
          item.nombre_center_status,
          item.potencia_maxima_anual,
          theme.palette.primary.main,
          `${item.potencia_maxima_anual.toFixed(2)}MWh`,
        ]),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title={
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="5px"
            flexWrap={'wrap'}
          >
            <Typography variant="body1" fontWeight={600}>
              Demanda de potencia máxima |
            </Typography>
            <Typography variant="body1">
              este año {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Chart
          chartType="ColumnChart"
          height={'200px'}
          width={'100%'}
          data={data}
          options={{
            backgroundColor: 'transparent',
            legend: { position: 'none' },
            vAxis: {
              title: '',
              minValue: 0,
              format: '#,###MWh',
              textStyle: {
                color: theme.palette.text.primary,
              },
            },
            hAxis: {
              title: '',
              slantedText: true,
              slantedTextAngle: 30,
              textStyle: {
                color: theme.palette.text.primary,
                fontSize: 10,
              },
            },
            annotations: {
              textStyle: {
                fontSize: 12,
                color: theme.palette.text.primary,
              },
            },
            bar: { groupWidth: '75%' },
            chartArea: {
              width: '75%',
              height: '70%',
              top: '10%',
              bottom: '50%',
            },
            series: {
              0: { annotations: { format: '#,###MWh' } },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default GeneralEnergyMaximumPowerDemand;
