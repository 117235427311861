import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import http from '../../infrastructure/api/http';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

type CustomerSelectorProps = {
  onCancel?: () => void;
  onConfirm?: (customerId: any) => void;
  companyId?: number;
};

export type GeneralReportStatuses = {
  id: number;
  name: string;
  title: string;
};

export type GeneralReportCenter = {
  id: number;
  name: string;
  statuses: GeneralReportStatuses[];
};

export type GeneralReportCustomer = {
  id: number;
  name: string;
  glosa: string;
  centers: GeneralReportCenter[];
};

const CustomerSelector: FC<CustomerSelectorProps> = ({
  onCancel,
  onConfirm,
  companyId,
}) => {
  const [companies, setCompanies] = useState<GeneralReportCustomer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [subExpanded, setSubExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await http.get<GeneralReportCustomer[]>(
        `/general-report/customers`
      );
      setCompanies(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      setExpanded(`company_${companyId}`);
    }
  }, [companyId]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography component="h1" variant="h5">
              Selección de Dispositivo
            </Typography>
            <Typography component="small">Seleccionar un elemento</Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Button onClick={() => onCancel?.()} sx={{ p: 0 }}>
            <CloseIcon />
          </Button>
        </Grid>
        {loading && (
          <Grid item xs={12}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <CircularProgress />
              <Typography variant="body1" color="text.secondary">
                Cargando datos...
              </Typography>
            </Box>
          </Grid>
        )}
        {!loading && companies.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              No hay empresas registradas, por favor cree una nueva empresa para
              poder seleccionar un dispositivo.
            </Alert>
          </Grid>
        )}
        {!loading && companies.length > 0 && (
          <Grid item xs={12}>
            {companies.map((company) => (
              <Accordion
                key={company.id}
                expanded={expanded === `company_${company.id}`}
                onChange={handleChange(`company_${company.id}`)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {company.name}
                </AccordionSummary>
                <AccordionDetails>
                  {company.centers.length > 0 && (
                    <List sx={{ width: '100%' }} component={'nav'}>
                      {company.centers.map((customer) => (
                        <Fragment key={customer.id}>
                          <ListItemButton
                            key={customer.id}
                            onClick={() => {
                              setSubExpanded(
                                subExpanded === `customer_${customer.id}`
                                  ? false
                                  : `customer_${customer.id}`
                              );
                            }}
                          >
                            <ListItemIcon>
                              <ArrowForwardIcon />
                            </ListItemIcon>
                            <ListItemText primary={customer.name} />
                            {subExpanded === `customer_${customer.id}` ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItemButton>
                          <Collapse
                            in={subExpanded === `customer_${customer.id}`}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {customer.statuses.map((ar) => (
                                <ListItemButton key={ar.id} sx={{ pl: 4 }}>
                                  <ListItemIcon>
                                    <ArrowForwardIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={ar.title} />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        </Fragment>
                      ))}
                    </List>
                  )}
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onConfirm?.(company)}
                  >
                    Seleccionar
                  </Button>
                  <Button onClick={() => onCancel?.()}>Cancelar</Button>
                </AccordionActions>
              </Accordion>
            ))}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CustomerSelector;
