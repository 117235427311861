import { FC, Fragment, useEffect, useState } from 'react';
import http from '../../../infrastructure/api/http';
import {
  GeneralReportCenter,
  GeneralReportCustomer,
} from '../CustomerSelector';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

type CenterSelectorProps = {
  onCancel?: () => void;
  onConfirm?: (center: GeneralReportCenter) => void;
  customerId: number;
  centerId: number;
};

const CenterSelector: FC<CenterSelectorProps> = ({
  customerId,
  centerId,
  onCancel,
  onConfirm,
}) => {
  const [centers, setCenters] = useState<GeneralReportCenter[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await http.get<GeneralReportCustomer[]>(
        `/general-report/customers`
      );
      const customer = response.data.find(
        (customer) => customer.id === customerId
      );
      const centers = customer?.centers;
      if (centers) setCenters(centers);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [customerId]);

  useEffect(() => {
    setExpanded(`center_${centerId}`);
  }, [centerId]);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography component="h1" variant="h5">
              Selección de Dispositivo
            </Typography>
            <Typography component="small">Seleccionar un elemento</Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Button onClick={() => onCancel?.()} sx={{ p: 0 }}>
            <CloseIcon />
          </Button>
        </Grid>
        {loading && (
          <Grid item xs={12}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <CircularProgress />
              <Typography variant="body1" color="text.secondary">
                Cargando datos...
              </Typography>
            </Box>
          </Grid>
        )}
        {!loading && centers.length === 0 && (
          <Grid item xs={12}>
            <Alert severity="info">
              No hay centros registrados, por favor cree una nueva empresa para
              poder seleccionar un dispositivo.
            </Alert>
          </Grid>
        )}
        {!loading && centers.length > 0 && (
          <Grid item xs={12}>
            {centers.map((center) => (
              <Accordion
                key={center.id}
                expanded={expanded === `center_${center.id}`}
                onChange={handleChange(`center_${center.id}`)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {center.name}
                </AccordionSummary>
                <AccordionDetails>
                  {center.statuses.length > 0 && (
                    <List component="div" disablePadding>
                      {center.statuses.map((ar) => (
                        <ListItemButton key={ar.id} sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <ArrowForwardIcon />
                          </ListItemIcon>
                          <ListItemText primary={ar.title} />
                        </ListItemButton>
                      ))}
                    </List>
                  )}
                </AccordionDetails>
                <AccordionActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onConfirm?.(center)}
                  >
                    Seleccionar
                  </Button>
                  <Button onClick={() => onCancel?.()}>Cancelar</Button>
                </AccordionActions>
              </Accordion>
            ))}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CenterSelector;
