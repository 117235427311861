import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BoltIcon from '@mui/icons-material/Bolt';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import { GeneralReportCustomer } from './CustomerSelector';
import http from '../../infrastructure/api/http';

type GeneralStatisticsCompanyProps = {
  company: GeneralReportCustomer;
};
const GeneralStatisticsCompany: FC<GeneralStatisticsCompanyProps> = ({
  company,
}) => {
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const [anualConsumption, setAnualConsumption] = useState('-1');

  const loadData = async (customerId: number) => {
    try {
      const response = await http.get<{ data: number }>(
        `/general-report/customers/${customerId}/anual-consumption`
      );
      setAnualConsumption(`${response.data.data.toFixed(2)}`); 
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: 'background.default',
          p: 1,
        }}
        title={<Typography variant={'body1'}>{company.name}</Typography>}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              borderRight: isSmallScreen ? 'none' : '1px solid #ddd',
            }}
          >
            <Box
              component={'div'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={1}
            >
              <Box component={'div'} display={'flex'} flexDirection={'row'}>
                <LocationOnIcon />
                <Typography variant={'body1'}>Localizaciones</Typography>
              </Box>
              <Typography variant={'h6'}>
                {company.centers.reduce((acc, curr) => acc + curr.statuses.length, 0)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component={'div'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              gap={1}
            >
              <Box component={'div'} display={'flex'} flexDirection={'row'}>
                <BoltIcon />
                <Typography variant={'body1'}>Consumo Anual</Typography>
              </Box>
              <Box
                component={'div'}
                display={'flex'}
                flexDirection={'row'}
                gap={1}
                alignItems={'center'}
              >
                <Typography variant={'h6'}>{anualConsumption === '-1' ? '-' : anualConsumption}</Typography>
                <Typography variant={'body2'}>GWh</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GeneralStatisticsCompany;
