import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, Grid, FormControl } from '@mui/material';
import moduleService from '../../application/services/ModuleService';
import { Lamp } from '../../domain/entities/Lamp';

type Props = {
  onCancel?: () => void;
  onCreated?: () => void;
  moduleId: number;
  centerId: number;
  jailId: number;
  lamp: Lamp | null;
};

type FormData = {
  nombre: string;
};

const LampForm: FC<Props> = ({
  onCancel,
  onCreated,
  centerId,
  moduleId,
  jailId,
  lamp,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      if (!lamp) {
        await moduleService.createLamp(data.nombre, moduleId, centerId, jailId);
      } else {
        await moduleService.updateLamp(
          data.nombre,
          moduleId,
          centerId,
          jailId,
          lamp.id
        );
      }
      onCreated?.();
    } catch (error) {
      alert(`Se ha producido un error al guardar el módulo`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (lamp) {
      setValue('nombre', lamp.document);
    }
  }, [lamp, setValue]);

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Nombre"
              variant="outlined"
              type="number"
              error={!!errors.nombre}
              helperText={errors.nombre && 'El nombre es requerido'}
              {...register('nombre', { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => {
              onCancel?.();
            }}
            disabled={isLoading}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LampForm;
