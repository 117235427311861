import { Alert, Box, Button, Grid, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import CustomerSelector from '../components/CustomerSelector';
import GeneralResumeComponent from '../components/GeneralResume';

const GeneralResume = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [company, setCompany] = useState<any | null>(null);
  const isSmallScreen = useSelector(selectIsSmallScreen);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <Typography component="h1" variant="h5" flex={1}>
            Resumen general
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setOpenModal(true)}
          >
            {company ? company.name : 'Seleccionar Empresa'}
          </Button>
        </Grid>
        {!company && (
          <Grid item xs={12} style={{ paddingTop: isSmallScreen ? 20 : 55 }}>
            <Alert severity="info">
              Bienvenido al dashboard <strong>Resumen General</strong>. No hay
              una empresa seleccionada, por favor elija el dispositivo y haga
              click en aceptar.
            </Alert>
          </Grid>
        )}
        {company && (
          <Grid item xs={12} style={{ paddingTop: isSmallScreen ? 20 : 55 }}>
            <GeneralResumeComponent company={company} />
          </Grid>
        )}
      </Grid>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isSmallScreen ? '80%' : '50%',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 24,
            p: 2,
          }}
        >
          <CustomerSelector
            companyId={company?.id}
            onCancel={() => {
              setOpenModal(false);
            }}
            onConfirm={(customer) => {
              setCompany(customer);
              setOpenModal(false);
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default GeneralResume;
