import { Box, Card, CardContent, Typography } from '@mui/material';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import { GeneralReportCenter } from '../CustomerSelector';
import { FC } from 'react';

type PowerCutsProps = {
  center: GeneralReportCenter;
};

const PowerCuts: FC<PowerCutsProps> = ({ center }) => {
  return (
    <Card style={{ height: '250px' }}>
      <CardContent style={{ height: '100%' }}>
        <Box
          component="div"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'15px'}
          height={'100%'}
        >
          <FlashOffIcon fontSize="large" />
          <Typography variant="h5" fontWeight={600}>
            38 cortes
          </Typography>
          <Typography variant="h5" fontWeight={600}>
            5d 1h 7m
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PowerCuts;
