import React from 'react';
import { GeneralReportCenter } from '../CustomerSelector';
import { Box, Grid } from '@mui/material';
import GeneralStatistics from './GeneralStatistics';
import EnergyConsumedMonth from './EnergyConsumedMonth';
import EnergyConsumedMonthProjected from './EnergyConsumedMonthProjected';
import EnergyConsumedMonthByStatus from './EnergyConsumedMonthByStatus';
import EnergyConsumedByStatus from './EnergyConsumedByStatus';
import EnergyDailyMonth from './EnergyDailyMonth';
import PotencyFactorMonth from './PotencyFactorMonth';
import PotencyMaxMonth from './PotencyMaxMonth';
import EnergyQuality from './EnergyQuality';
import PowerCuts from './PowerCuts';

type GeneralResumeCenterProps = {
  center: GeneralReportCenter;
};

const GeneralResumeCenter: React.FC<GeneralResumeCenterProps> = ({
  center,
}) => {
  return (
    <Box component={'div'} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <GeneralStatistics center={center} />
        </Grid>
        <Grid item xs={0} md={8}></Grid>
        <Grid item xs={12} md={3}>
          <EnergyConsumedMonth center={center} />
        </Grid>
        <Grid item xs={12} md={3}>
          <EnergyConsumedMonthProjected center={center} />
        </Grid>
        <Grid item xs={12} md={6}>
          <EnergyConsumedMonthByStatus center={center} />
        </Grid>
        <Grid item xs={12} md={6}>
          <EnergyConsumedByStatus center={center} />
        </Grid>
        <Grid item xs={12} md={6}>
          <EnergyDailyMonth center={center} />
        </Grid>
        <Grid item xs={12} md={3}>
          <PotencyFactorMonth center={center} />
        </Grid>
        <Grid item xs={12} md={3}>
          <PotencyMaxMonth center={center} />
        </Grid>
        <Grid item xs={12} md={3}>
          <EnergyQuality center={center} />
        </Grid>
        <Grid item xs={12} md={3}>
          <PowerCuts center={center} />
        </Grid>
        <Grid item xs={12}>
          {/* <GeneralEnergyMaximumPowerDemand company={company} /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralResumeCenter;
