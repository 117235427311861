import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import http from '../../infrastructure/api/http';
import { GeneralReportCustomer } from './CustomerSelector';
import moment from 'moment';

type ResponseData = {
  consumo_mensual: number;
  mes: string;
};

type GeneralEnergyYearBarsProps = {
  company: GeneralReportCustomer;
};

const GeneralEnergyYearBars: FC<GeneralEnergyYearBarsProps> = ({ company }) => {
  const theme = useTheme();
  const [data, setData] = useState([
    ['', '', { role: 'annotation' }],
    ['Diciembre', 0, '0MWh'],
    ['Noviembre', 0, '0MWh'],
    ['Octubre', 0, '0MWh'],
    ['Septiembre', 0, '0MWh'],
    ['Agosto', 0, '0MWh'],
    ['Julio', 0, '0MWh'],
    ['Junio', 0, '0MWh'],
    ['Mayo', 0, '0MWh'],
    ['Abril', 0, '0MWh'],
    ['Marzo', 0, '0MWh'],
    ['Febrero', 0, '0MWh'],
    ['Enero', 0, '0MWh'],
  ]);

  const generateMonthsOfYear = () => {
    const currentYear = moment().year();

    return Array.from({ length: 12 }, (_, month) =>
      moment({ year: currentYear, month })
    );
  };

  const loadData = async (customerId: number) => {
    try {
      const response = await http.get<ResponseData[]>(
        `/general-report/customers/${customerId}/centers/by-month-consumption`
      );
      const months = generateMonthsOfYear();
      const monthsData = months.map((month) => {
        const found = response.data.find(
          (data) => moment(`${data.mes}`, 'YYYY-MM').format('YYYY-MM') === month.format('YYYY-MM')
        );

        if(found) {
          return [month.format('MMMM'), found.consumo_mensual, `${found.consumo_mensual.toFixed(2)}MWh`];
        }

        return [month.format('MMMM'), 0, '0MWh'];
      });

      setData([
        ['', '', { role: 'annotation' }],
        ...monthsData.reverse(),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card>
      <CardHeader
        title={
          <Box component="div" display="flex" flexDirection="row" gap="5px">
            <Typography variant={'body1'} fontWeight={600}>
              Consumo de energía
            </Typography>
            <Typography variant={'body1'}>
              | este año {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data}
          options={{
            backgroundColor: 'transparent',
            chartArea: { height: '80%' },
            hAxis: {
              minValue: 0,
              textStyle: {
                color: theme.palette.text.primary,
              },
              format: '###MWh',
            },
            vAxis: {
              textStyle: {
                color: theme.palette.text.primary,
              },
            },
            legend: 'none',
            annotations: {
              alwaysOutside: false,
              textStyle: {
                color: theme.palette.text.primary,
                fontSize: 10,
              },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default GeneralEnergyYearBars;
