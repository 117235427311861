import { FC, useState } from 'react';
import { GeneralReportCenter } from '../CustomerSelector';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { Chart } from 'react-google-charts';

type EnergyDailyMonthProps = {
  center: GeneralReportCenter;
};

const EnergyDailyMonth: FC<EnergyDailyMonthProps> = ({ center }) => {
  const theme = useTheme();
  const [data, setData] = useState<any[]>([]);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title={
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="5px"
            flexWrap={'wrap'}
          >
            <Typography variant="body1" fontWeight={600}>
              Consumo de energía |
            </Typography>
            <Typography variant="body1">
              este año {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Chart
          chartType="ColumnChart"
          height={'200px'}
          width={'100%'}
          data={data}
          options={{
            backgroundColor: 'transparent',
            legend: { position: 'none' },
            vAxis: {
              title: '',
              minValue: 0,
              maxValue: 1500,
              ticks: [0, 500, 1000, 1500],
              format: '#,###MWh',
              gridlines: {
                count: 15, // Esto aumentará el número de líneas de cuadrícula, reduciendo el espacio entre ellas
              },
              textStyle: {
                color: theme.palette.text.primary,
              },
            },
            hAxis: {
              title: '',
              slantedText: true,
              slantedTextAngle: 30,
              textStyle: {
                fontSize: 10,
                color: theme.palette.text.primary,
              },
            },
            annotations: {
              textStyle: {
                fontSize: 12,
                color: theme.palette.text.primary,
              },
            },
            bar: { groupWidth: '75%' },
            chartArea: {
              width: '75%',
              height: '70%',
              top: '10%',
              bottom: '50%',
            },
            series: {
              0: { annotations: { format: '#,###MWh' } },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default EnergyDailyMonth;
