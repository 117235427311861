import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { GeneralReportCenter } from '../CustomerSelector';
import { FC, useState } from 'react';
import { Chart } from 'react-google-charts';

type EnergyConsumedMonthByStatusProps = {
  center: GeneralReportCenter;
};

const EnergyConsumedMonthByStatus: FC<EnergyConsumedMonthByStatusProps> = ({
  center,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);

  return (
    <Card style={{ height: '250px' }}>
      <CardHeader
        title={
          <Box component="div" display="flex" flexDirection="row" gap="5px">
            <Typography variant={'body1'} fontWeight={600}>
              Consumo de energía mensual por AR
            </Typography>
            <Typography variant={'body1'}>
              | {moment().format('MMMM')} {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        {loading && (
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
        {!loading && data.length === 0 && (
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="5px"
          >
            <Typography variant="h5" fontWeight={600}>
              No hay datos disponibles
            </Typography>
            <Typography variant="body1">
              No hay datos disponibles para el mes actual
            </Typography>
          </Box>
        )}
        {!loading && data.length > 0 && (
          <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data}
            options={{
              pieHole: 0.5,
              backgroundColor: 'transparent',
              legend: {
                position: 'right',
                textStyle: {
                  color: theme.palette.text.primary,
                },
              },
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default EnergyConsumedMonthByStatus;
