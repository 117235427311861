import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import { GeneralReportCustomer } from './CustomerSelector';
import http from '../../infrastructure/api/http';
import { Link } from 'react-router-dom';

type ResponseData = {
  id_centro: number;
  nombre_centro: string;
  energia_total_consumida: number;
  potencia_maxima: number;
};

type GeneralEnergyYearProps = {
  company: GeneralReportCustomer;
};

const GeneralEnergyYear: FC<GeneralEnergyYearProps> = ({ company }) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rows, setRows] = useState<ResponseData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const columns: GridColDef[] = [
    {
      field: 'id_centro',
      headerName: 'No',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'nombre_centro',
      headerName: 'Centro',
      sortable: false,
      disableColumnMenu: true,
      width: 200,
    },
    {
      field: 'energia_total_consumida',
      headerName: 'Energía',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return `${params.row.energia_total_consumida.toFixed(2)} MWh`;
      }
    },
    {
      field: 'potencia_maxima',
      headerName: 'Pot. Máx.',
      sortable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return `${params.row.potencia_maxima.toFixed(2)} kW`;
      }
    },
    {
      field: 'action',
      headerName: 'Link',
      type: 'actions',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Link to={`/general-resume/${company.id}/${params.row.id_centro}`}>
            <Button variant='outlined'>Ver +</Button>
          </Link>
        );
      },
    },
  ];

  const loadData = async (customerId: number) => {
    try {
      setLoading(true);
      const response = await http.get<ResponseData[]>(
        `/general-report/customers/${customerId}/centers/anual-consumption`
      );
      setRows(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card>
      <CardHeader
        title={
          <Box component="div" display="flex" flexDirection="row" gap="5px">
            <Typography variant={'body1'} fontWeight={600}>
              Consumo de energía
            </Typography>
            <Typography variant={'body1'}>
              | este año {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Paper sx={{ height: 400, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row.id_centro}
            loading={loading}
            rows={rows}
            columns={columns}
            initialState={{ pagination: { paginationModel: paginationModel } }}
            sx={{ border: 0 }}
          />
        </Paper>
      </CardContent>
    </Card>
  );
};

export default GeneralEnergyYear;
