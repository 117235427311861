import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { GeneralReportCustomer } from './CustomerSelector';
import http from '../../infrastructure/api/http';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

type ResponseData = {
  consumo_mes_actual_parcial: number;
  consumo_proyectado_mes_actual: number;
  consumo_mes_anterior: number;
  porcentaje_variacion: number;
};

type GeneralEnergyProjectedConsumptionProps = {
  company: GeneralReportCustomer;
};
const GeneralEnergyProjectedConsumption: FC<
  GeneralEnergyProjectedConsumptionProps
> = ({ company }) => {
  const [data, setData] = useState(-1);
  const [percent, setPercent] = useState(0);

  const loadData = async (customerId: number) => {
    try {
      const response = await http.get<ResponseData>(
        `/general-report/customers/${customerId}/month-projected-consumption`
      );
      setData(response.data.consumo_proyectado_mes_actual);
      setPercent(response.data.porcentaje_variacion);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData(company.id);
  }, [company]);

  return (
    <Card>
      <CardHeader
        title={
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            gap="5px"
            flexWrap={'wrap'}
          >
            <Typography variant="body1" fontWeight={600}>
              Consumo proyectado |
            </Typography>
            <Typography variant="body1">
              {moment().format('MMMM')} {new Date().getFullYear()}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap="5px"
              flexWrap={'wrap'}
            >
              <Typography variant="h5" fontWeight={600}>
                {data === -1 ? '-' : data.toFixed(2)}
              </Typography>
              <Typography variant="body1">MWh</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" style={{ borderTop: '1px solid #009581' }} />
          </Grid>
          <Grid item xs={12}>
            <Box
              component="div"
              display="flex"
              flexDirection="row"
              gap="5px"
              flexWrap={'wrap'}
              alignItems={'center'}
            >
              {percent > 0 ? (
                <>
                  <NorthIcon
                    fontSize="small"
                    style={{ color: '#009581', fontWeight: 600 }}
                  />
                  <Typography variant="body1" color="#009581">
                    {percent.toFixed(2)} % que el mes anterior
                  </Typography>
                </>
              ) : (
                <>
                  <SouthIcon
                    fontSize="small"
                    style={{ color: '#e74c3c', fontWeight: 600 }}
                  />
                  <Typography variant="body1" color="#e74c3c">
                    {Math.abs(percent).toFixed(2)} % que el mes anterior
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GeneralEnergyProjectedConsumption;
