import { FC, useState, useEffect } from 'react';
import DashboardCenter from '../components/DashboardCenter';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../domain/store/session/selectors';
import { setCustomer } from '../../domain/store/session';
import DashboardManager from '../components/DashboardManager';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserAccess } from '../../shared/enums/user-access.enum';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Customer } from '../../domain/entities/Customer';
import http from '../../infrastructure/api/http';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import ICON_CONFIG from '../../shared/assets/menu/icon_zoom.png';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import DashboardManagerMobile from '../components/DashboardManagerMobile';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CachedIcon from '@mui/icons-material/Cached';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';

moment.tz.setDefault('America/Santiago');

function openFullscreen() {
  const elem: any = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
}

const HomePage: FC = () => {
  const [zoom, setZoom] = useState(100);
  const [showConfigPage, setShowConfigPage] = useState(false);
  const currentUser = useSelector(selectUser);
  let [searchParams, setSearchParams] = useSearchParams();
  const [customerId, setCustomerId] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<string>(
    moment().format('dddd DD/MM/YYYY, hh:mm A').charAt(0).toUpperCase() +
      moment().format('dddd DD/MM/YYYY, hh:mm A').slice(1)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCron = async () => {
    try {
      setLoading(true);
      await http.post('/customers/run-cron');
      await loadData();
    } catch (error) {
      alert('[Error] No es posible actualizar');
    } finally {
      setLoading(false);
    }
  };

  const loadData = async () => {
    try {
      const response = await http.get(`/customers`);
      setCustomers(response.data.customers);
      if (response.data.customers.length > 0 && customerId === 0) {
        setCustomerId(response.data.customers[0].id);
        dispatch(setCustomer(response.data.customers[0]));
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  useEffect(() => {
    if (currentUser?.access === UserAccess.SUPER_USER) {
      (async () => {
        await loadData();
      })();
      return () => {
        setCustomers([]);
      };
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.access !== UserAccess.SUPER_USER) {
        (async () => {
          try {
            const response = await http.get(
              `/customers/${currentUser.fidempresa}`
            );
            console.log(response);
            setCustomers([response.data]);
            setCustomerId(currentUser.fidempresa);
            dispatch(setCustomer(response.data));
          } catch (error: any) {
            alert(error.message);
          }
        })();
      } else {
        setCustomerId(currentUser.fidempresa);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(
        moment().format('dddd DD/MM/YYYY, hh:mm A').charAt(0).toUpperCase() +
          moment().format('dddd DD/MM/YYYY, hh:mm A').slice(1)
      );
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    (document.body.style as any).zoom = `${zoom}%`;
  }, [zoom]);

  return (
    <Grid container spacing={2} sx={{ px: 1 }}>
      {!isSmallScreen && (
        <Box
          position="absolute"
          top={showConfigPage ? '-9vh' : '-200px'}
          right="0"
          sx={{
            transition: 'all 0.3s ease-in-out',
            bgcolor: 'background.default',
            borderBottomLeftRadius: '5px',
            p: '5px',
          }}
        >
          <Stack direction="row" gap={1} sx={{ position: 'relative' }}>
            <IconButton
              onClick={() => {
                setZoom(zoom + 10);
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setZoom(zoom - 10);
              }}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                openFullscreen();
              }}
            >
              <FullscreenIcon />
            </IconButton>
            <Box
              position="absolute"
              bottom="-30px"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                padding="2px"
                sx={{
                  cursor: 'pointer',
                  bgcolor: 'background.default',
                  borderBottomLeftRadius: '5px',
                  borderBottomRightRadius: '5px',
                }}
                onClick={() => {
                  setShowConfigPage(false);
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Stack>
        </Box>
      )}
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        item
        xs={12}
        sm={
          currentUser?.access === UserAccess.SUPER_USER &&
          searchParams.get('center') === null
            ? 8
            : 8
        }
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box
          component={'div'}
          display="flex"
          flexDirection={'row'}
          alignItems={'center'}
        >
          <Typography
            component="h1"
            variant="h5"
            flex={1}
            fontSize={isSmallScreen ? '20px' : undefined}
          >
            {isSmallScreen ? 'Resumen de estado' : 'Resumen de estado global'}
          </Typography>
          {/* {isSmallScreen && (
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                navigate('/reports');
              }}
            >
              <img src={ICON_REPORT} alt="Descarga reporte" width="48" />
            </IconButton>
          )} */}
        </Box>
        <Typography
          component="small"
          variant="subtitle2"
          color="#fff"
          style={{ fontWeight: 'bold', marginTop: isSmallScreen ? '2rem' : 10 }}
        >
          {isSmallScreen && <KeyboardArrowDownIcon />} {currentTime}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={
          currentUser?.access === UserAccess.SUPER_USER &&
          searchParams.get('center') === null
            ? 4
            : 4
        }
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        gap={2}
      >
        {!isSmallScreen && (
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              setShowConfigPage(true);
            }}
          >
            <img src={ICON_CONFIG} alt="Descarga reporte" width="32" />
          </IconButton>
        )}
        {/* {!isSmallScreen && (
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              navigate('/reports');
            }}
          >
            <img src={ICON_REPORT} alt="Descarga reporte" width="32" />
          </IconButton>
        )} */}
        {!isSmallScreen && (
          <Button
            onClick={handleCron}
            sx={{
              padding: '5px',
              borderRadius: '5px',
              background:
                'linear-gradient(135deg, rgba(35, 69, 72, 1), rgba(68, 70, 73, 0.9))',
              textTransform: 'none',
            }}
          >
            <Stack
              direction="row"
              spacing={'5px'}
              alignItems="center"
              color="#fff"
            >
              <Typography
                variant="body2"
                color="#fff"
                textOverflow={'ellipsis'}
                overflow={'hidden'}
                whiteSpace={'nowrap'}
                width={'80px'}
              >
                Actualizar
              </Typography>
              <CachedIcon />
            </Stack>
          </Button>
        )}
        {currentUser?.access === UserAccess.SUPER_USER &&
          searchParams.get('center') === null && (
            <div>
              <Select
                label="Cliente"
                onChange={(e) => {
                  setCustomerId(e.target.value as number);
                  const customer = customers.find(
                    (c) => c.id === (e.target.value as number)
                  );
                  if (customer) {
                    dispatch(setCustomer(customer));
                  }
                }}
                value={customerId}
                fullWidth
              >
                {customers.length === 0 && (
                  <MenuItem value={0} selected disabled>
                    No hay clientes
                  </MenuItem>
                )}
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
      </Grid>
      {customerId !== null && (
        <>
          {(currentUser?.access === UserAccess.CLI_CENTER ||
            ((currentUser?.access === UserAccess.CLI_ADMIN ||
              currentUser?.access === UserAccess.SUPER_USER ||
              currentUser?.access === UserAccess.ENERPRY) &&
              searchParams.get('center') !== null)) && (
            <Grid item xs={12}>
              <DashboardCenter
                customerId={customerId}
                showBack={searchParams.get('center') !== null}
                onBack={() => {
                  setSearchParams({});
                }}
                centerId={searchParams.get('center')}
              />
            </Grid>
          )}

          {(currentUser?.access === UserAccess.CLI_ADMIN ||
            currentUser?.access === UserAccess.ENERPRY ||
            currentUser?.access === UserAccess.SUPER_USER) &&
            searchParams.get('center') === null && (
              <Grid item xs={12}>
                {!isSmallScreen && (
                  <DashboardManager
                    customerId={customerId}
                    onSelectCenter={(center) => {
                      setSearchParams({ center: center.id }, {
                        state: { center: center },
                      });
                    }}
                  />
                )}
                {isSmallScreen && (
                  <DashboardManagerMobile
                    customerId={customerId}
                    onSelectCenter={(center) => {
                      setSearchParams({ center: center.id });
                    }}
                  />
                )}
              </Grid>
            )}
        </>
      )}
    </Grid>
  );
};

export default HomePage;
